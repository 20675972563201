<template>
  <div class="container my-3 my-md-8">
    <!-- 進度條 -->
    <nav class="progress">
      <div
        class="progress-bar"
        :class="{ active: step === 0, finished: step & 0xff }"
        role="progressbar"
        aria-valuenow="20"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span>購物車</span>
      </div>
      <div
        class="progress-bar"
        :class="{ active: step === 1, finished: step & 0xff }"
        role="progressbar"
        aria-valuenow="40"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span>填寫訂購資料</span>
      </div>
      <div
        class="progress-bar"
        :class="{ active: step === 2, finished: step & 0x06 }"
        role="progressbar"
        aria-valuenow="60"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span>確定結帳</span>
      </div>
      <div
        class="progress-bar"
        :class="{ active: step === 3, finished: step & 0x04 }"
        role="progressbar"
        aria-valuenow="80"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span>進行付款</span>
      </div>
      <div
        class="progress-bar"
        :class="{ active: step === 4 }"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span>完成訂單</span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: ['step'],
};
</script>

<style></style>

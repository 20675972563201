<template>
  <section>
    <ShoppingProgress :step.sync="step" />
    <section class="pb-8">
      <div class="container">
        <div class="order-bg mb-3 mb-md-8 fail">
          <div class="fail">
            <p>
              訂單編號:{{ orderNo }}<br />
              訂單狀態:付款失敗<br />
              有任何問題請聯絡客服人員:<br />
              <a href="mailto: Service@readerplace.com.tw">Service@readerplace.com.tw</a>
            </p>
          </div>
        </div>
        <!-- 下一步按鈕 -->
        <div class="d-flex justify-content-end">
          <router-link
            class="btn btn-primary btn-hover-shadow px-6 py-2 py-md-3"
            to="/member/orders"
          >
            查看訂單
          </router-link>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import ShoppingProgress from '@/components/ShoppingProgress.vue';

export default {
  components: {
    ShoppingProgress,
  },
  data() {
    return {
      step: 3,
      orderNo: '',
      errorCode: '',
    };
  },
  created() {
    const { i, c } = this.$route.query;
    this.orderNo = i;
    this.errorCode = c;
  },
  methods: {},
};
</script>

<style></style>
